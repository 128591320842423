<template>
    <div v-loading="pageLoading">
        <div class="card mb-5 mb-xl-10" v-if="formData.shipping_method_id">
            <div class="card-header border-0 pt-6">
                <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2 float-left">
                    <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldShippingData.name }}</span>
                </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header border-0 pt-6">
                <el-form ref="formRef" :model="formData" :rules="rules" class="w-100 demo-ruleForm" status-icon>
                    <div class="row g-9">
                        <div class="col-md-3 fv-row">
                            <div class="mb-6">
                                <label class="required fs-6 fw-bold mb-2">{{ orderConstants.SHIPPING_METHOD }}</label>
                                <el-form-item prop="shipping_method">
                                    <el-input size="large" v-model="formData.shipping_method" type="text" placeholder="Name"/>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-3 fv-row" v-if="formData.third_party_billing > 0">
                            <div class="mb-6">
                                <label class="required fs-6 fw-bold mb-2">Third Party Billing</label>
                                <el-form-item prop="third_party_billing">
                                    <el-radio-group v-model="formData.third_party_billing">
                                        <el-radio :label="1" > No </el-radio>
                                        <el-radio :label="2"> Yes </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-3 fv-row">
                            <div class="mb-6">
                                <label class="fs-6 fw-bold mb-2">{{ generalConstants.STATUS }}</label>
                                <el-form-item prop="status">
                                    <el-switch v-model="formData.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div v-if="formData.shipping_mode == 1">
                        <div class="row g-9">
                            <div class="col-md-12 mb-2">
                                <hr>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Select Shipping Environment</label>
                                    <el-form-item prop="shipping_details.shipping_type">
                                        <el-radio-group v-model="formData.shipping_details.shipping_type">
                                            <el-radio :label="0" > Live </el-radio>
                                            <el-radio :label="1"> Testing </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-6 mb-2">
                                <h4>Account Details</h4>
                            </div>
                            <div class="col-md-6 mb-2">
                                <h4>Package Details</h4>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Access Key</label>
                                    <el-form-item prop="shipping_details.access_key">
                                        <el-input v-model="formData.shipping_details.access_key" size="large" type="text" placeholder="Access Key"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'UPS'">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Customer Id / Username</label>
                                    <el-form-item prop="shipping_details.username">
                                        <el-input v-model="formData.shipping_details.username" size="large" type="text" placeholder="Customer Id / Username"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'Fedex'">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Meter Number</label>
                                    <el-form-item prop="shipping_details.meter_number">
                                        <el-input v-model="formData.shipping_details.meter_number" size="large" type="text" placeholder="Meter Number"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">{{ packageConstants.WEIGHT_UNIT }}</label>
                                    <el-form-item prop="shipping_details.weight_class">
                                    <el-select v-model="formData.shipping_details.weight_class" :placeholder="packageConstants.CHOOSE_WEIGHT" size="large" class="w-100">
                                        <el-option :label="weightval" :value="weightkey" :key="weightkey"  v-for="(weightval, weightkey) in weightClass.value"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">{{ packageConstants.LENGTH_UNIT }}</label>
                                    <el-form-item prop="shipping_details.length_class">
                                    <el-select v-model="formData.shipping_details.length_class" :placeholder="packageConstants.CHOOSE_LENGTH" size="large" class="w-100">
                                        <el-option :label="lengthval" :value="lengthkey" :key="lengthkey"  v-for="(lengthval, lengthkey) in lengthClass.value"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Password</label>
                                    <el-form-item prop="shipping_details.password">
                                        <el-input v-model="formData.shipping_details.password" size="large" type="password" placeholder="Password"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Account Number</label>
                                    <el-form-item prop="shipping_details.account_number">
                                        <el-input v-model="formData.shipping_details.account_number" size="large" type="text" placeholder="Account Number"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">{{ packageConstants.MAX_WEIGHT }}</label>
                                    <el-form-item prop="shipping_details.max_weight">
                                    <el-input v-model="formData.shipping_details.max_weight" size="large" type="text" :placeholder="packageConstants.MAX_WEIGHT" />
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">{{ packageConstants.BOX_WEIGHT }}</label>
                                    <el-form-item prop="shipping_details.box_weight">
                                    <el-input v-model="formData.shipping_details.box_weight" size="large" type="text" :placeholder="packageConstants.BOX_WEIGHT" />
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Company Name</label>
                                    <el-form-item prop="shipping_details.company_name">
                                        <el-input v-model="formData.shipping_details.company_name" size="large" type="text" placeholder="Company Name"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'UPS'">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Attention Name</label>
                                    <el-form-item prop="shipping_details.attention_name">
                                        <el-input v-model="formData.shipping_details.attention_name" size="large" type="text" placeholder="Attention Name"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'Fedex'">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Name</label>
                                    <el-form-item prop="shipping_details.name">
                                        <el-input v-model="formData.shipping_details.name" size="large" type="text" placeholder="Name"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-6 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Dimensions</label>
                                    <div class="row g-9">
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.package_length">
                                                <el-input v-model="formData.shipping_details.package_length" size="large" type="number" min="0" :placeholder="packageConstants.LENGTH" />
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.package_height">
                                                <el-input v-model="formData.shipping_details.package_height" size="large" type="number" min="0" :placeholder="generalConstants.HEIGHT" />
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.package_width">
                                                <el-input v-model="formData.shipping_details.package_width" size="large" type="number" min="0" :placeholder="generalConstants.WIDTH" />
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Phone Number</label>
                                    <el-form-item prop="shipping_details.phone_number">
                                        <el-input v-model="formData.shipping_details.phone_number" size="large" type="text" placeholder="Phone Number"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <div v-if="formData.function == 'UPS'">
                                        <label class="fs-6 fw-bold mb-2">Address Line 1</label>
                                        <el-form-item prop="shipping_details.address1">
                                            <el-input v-model="formData.shipping_details.address1" size="large" type="text" placeholder="Address Line 1"/>
                                        </el-form-item>
                                    </div>
                                    <div v-if="formData.function == 'Fedex'">
                                        <label class="required fs-6 fw-bold mb-2">Address Line 1</label>
                                        <el-form-item prop="shipping_details.address1">
                                            <el-input v-model="formData.shipping_details.address1" size="large" type="text" placeholder="Address Line 1"/>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 fv-row">
                                <div class="mb-6">
                                    <h4>Configuration Setting</h4>
                                    <div class="row g-9">
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.transit_api">
                                                <el-checkbox v-model="formData.shipping_details.transit_api" label="Transit Time API"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.label_generation">
                                                <el-checkbox v-model="formData.shipping_details.label_generation" label="Label Generation"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.track_order">
                                                <el-checkbox v-model="formData.shipping_details.track_order" label="Customer track order"/>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <div  v-if="formData.function == 'UPS'">
                                        <label class="fs-6 fw-bold mb-2">City</label>
                                        <el-form-item prop="shipping_details.city">
                                            <el-input v-model="formData.shipping_details.city" size="large" type="text" placeholder="City"/>
                                        </el-form-item>
                                    </div>
                                    <div  v-if="formData.function == 'Fedex'">
                                        <label class="required fs-6 fw-bold mb-2">City</label>
                                        <el-form-item prop="shipping_details.city">
                                            <el-input v-model="formData.shipping_details.city" size="large" type="text" placeholder="City"/>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">State Code</label>
                                    <el-form-item prop="shipping_details.state">
                                        <el-input v-model="formData.shipping_details.state" size="large" type="text" placeholder="State Code"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-6 fv-row">
                                <div class="mb-6">
                                    <div class="row g-9">
                                        <div class="col-md-4 fv-row">
                                            <el-form-item prop="shipping_details.debug_mode">
                                                <el-checkbox v-model="formData.shipping_details.debug_mode" label="Debug Mode"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row" v-if="formData.function == 'UPS'">
                                            <el-form-item prop="shipping_details.negotiated_rates">
                                                <el-checkbox v-model="formData.shipping_details.negotiated_rates" label="Negotiated rates"/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-4 fv-row" v-if="formData.function == 'Fedex'">
                                            <el-form-item prop="shipping_details.fedex_freight">
                                                <el-checkbox v-model="formData.shipping_details.fedex_freight" label="Fedex Freight"/>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Postal Code</label>
                                    <el-form-item prop="shipping_details.postal_code">
                                        <el-input v-model="formData.shipping_details.postal_code" size="large" type="text" placeholder="Postal Code"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <div v-if="formData.function == 'UPS'">
                                        <label class="fs-6 fw-bold mb-2">Country Code</label>
                                        <el-form-item prop="shipping_details.country">
                                            <el-input v-model="formData.shipping_details.country" size="large" type="text" placeholder="Country Code"/>
                                        </el-form-item>
                                    </div>
                                    <div v-if="formData.function == 'Fedex'">
                                        <label class="required fs-6 fw-bold mb-2">Country Code</label>
                                        <el-form-item prop="shipping_details.country">
                                            <el-input v-model="formData.shipping_details.country" size="large" type="text" placeholder="Country Code"/>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'UPS'">
                                <div class="mb-6">
                                    <label class="required fs-6 fw-bold mb-2">Shipper Number</label>
                                    <el-form-item prop="shipping_details.shipper_number">
                                        <el-input v-model="formData.shipping_details.shipper_number" size="large" type="text" placeholder="Shipper Number"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row" v-if="formData.function == 'UPS'">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Tracking Language</label>
                                    <el-form-item prop="shipping_details.tracking_lan">
                                        <el-input v-model="formData.shipping_details.tracking_lan" size="large" type="text" placeholder="Tracking Language"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9" v-if="formData.function == 'UPS'">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Pickup Method</label>
                                    <el-form-item prop="shipping_details.pickup_method">
                                        <el-input v-model="formData.shipping_details.pickup_method" size="large" type="text" placeholder="Pickup Method"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Customer Classification Code</label>
                                    <el-form-item prop="shipping_details.customer_code">
                                        <el-input v-model="formData.shipping_details.customer_code" size="large" type="text" placeholder="Customer Classification Code"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9" v-if="formData.function == 'UPS'">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Email</label>
                                    <el-form-item prop="shipping_details.email">
                                        <el-input v-model="formData.shipping_details.email" size="large" type="email" placeholder="Email"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Quote Type</label>
                                    <el-form-item prop="shipping_details.quote_type">
                                        <el-input v-model="formData.shipping_details.quote_type" size="large" type="text" placeholder="Quote Type"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="row g-9" v-if="formData.function == 'Fedex'">
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Drop Off Type</label>
                                    <el-form-item prop="shipping_details.drop_off_type" >
                                        <el-input v-model="formData.shipping_details.drop_off_type" size="large" type="text" placeholder="Drop Off Type"/>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="col-md-3 fv-row">
                                <div class="mb-6">
                                    <label class="fs-6 fw-bold mb-2">Rate Type</label>
                                    <el-form-item prop="shipping_details.rate_type">
                                        <el-input v-model="formData.shipping_details.rate_type" size="large" type="text" placeholder="Rate Type"/>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-9">
                        <div class="col-md-12 mb-5 mt-12">
                            <button type="button" class=" btn btn-primary me-2" v-if="permission.isEditAllowed" :disabled="loading" @click="submitForm(formRef, 'save')">{{generalConstants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                            <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{generalConstants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                            <button class="btn btn-secondary me-1" :disabled="disabled" @click.prevent="$router.push('/shipping-method')">{{ generalConstants.CANCEL }}</button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, reactive, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { useRoute, useRouter } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
    name: "add-shipping-method",
    components: {},
    setup() {
        const { onEvent, emitEvent } = useEventBus()
        const route = useRoute();
        const router = useRouter();
        const formRef = ref(null);
        const loading = ref(false);
        const loadingback = ref(false);
        const disabled = ref(false);
        const pageLoading = ref(false);
        const generalConstants = globalConstant.general;
        const orderConstants = globalConstant.orders;
        const shippingConstants = globalConstant.shippingMethod;
        const packageConstants = globalConstant.package;
        const weightClass = reactive({});
        const lengthClass = reactive({});
        const oldShippingData = reactive({
            name: ''
        });
        const formData = reactive({
            shipping_method_id: 0,
            shipping_method: "",
            shipping_mode: "",
            third_party_billing: "",
            shipping_details: [],
            status: true
        });
        //Get click event of toolbar buttons
        onEvent('actionName', (actionName) => {
        if(actionName == "save") {
            submitForm(formRef, 'save', true)
        } else if (actionName == "save_back") {
            submitForm(formRef, 'save_back',true)
        } else if (actionName == "cancel") {
            router.push('/shipping-method');
        }
        })
        const permission = reactive({
            isEditAllowed: false
        })

        const rules = reactive({
            shipping_method: [
                {
                    required: true,
                    message: 'Shipping method is required',
                    trigger: 'change',
                }
            ],
            access_key: [
                {
                    required: true,
                    message: 'Access key is required',
                    trigger: 'change',
                }
            ],
            shipper_number: [
                {
                    required: true,
                    message: 'Shipper number is required',
                    trigger: 'change',
                }
            ],
            country :[
                {
                    required: true,
                    message: 'Country is required',
                    trigger: 'change',
                }
            ],
            postal_code :[
                {
                    required: true,
                    message: 'Postal code is required',
                    trigger: 'change',
                }
            ],
            state :[
                {
                    required: true,
                    message: 'State is required',
                    trigger: 'change',
                }
            ],
            city :[
                {
                    required: true,
                    message: 'City is required',
                    trigger: 'change',
                }
            ],
            label_generation :[
                {
                    required: true,
                    message: 'Label generation is required',
                    trigger: 'change',
                }
            ],
            address1 :[
                {
                    required: true,
                    message: 'Address line 1 is required',
                    trigger: 'change',
                }
            ],
            package_width :[
                {
                    required: true,
                    message: 'Width is required',
                    trigger: 'change',
                }
            ],
            package_height :[
                {
                    required: true,
                    message: 'Height is required',
                    trigger: 'change',
                }
            ],
            package_length :[
                {
                    required: true,
                    message: 'Length is required',
                    trigger: 'change',
                }
            ],
            name :[
                {
                    required: true,
                    message: 'Name is required',
                    trigger: 'change',
                }
            ],
            attention_name :[
                {
                    required: true,
                    message: 'Attention name is required',
                    trigger: 'change',
                }
            ],
            account_number :[
                {
                    required: true,
                    message: 'Account number is required',
                    trigger: 'change',
                }
            ],
            meter_number :[
                {
                    required: true,
                    message: 'Meter number is required',
                    trigger: 'change',
                }
            ],
            username :[
                {
                    required: true,
                    message: 'Username is required',
                    trigger: 'change',
                }
            ],
            password :[
                {
                    required: true,
                    message: 'Password is required',
                    trigger: 'change',
                }
            ]
        });

        //Submit shipping method data
        const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction = false) => {
            formEl = (isToolbarButtonsSubmitAction)?formEl.value:formEl;
            if (!formEl) {
                return;
            }
            formEl.validate((valid) => {
                if (!valid) return false;

                (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);

                let addParams = {
                    _method: "PUT",
                    shipping_method: formData.shipping_method,
                    third_party_billing: formData.third_party_billing,
                    shipping_details: JSON.stringify(formData.shipping_details),
                    status: formData.status
                };
                ApiService.post("shipping-method/" + formData.shipping_method_id, addParams)
                .then(({ data }) => {
                    if(data) {   
                        oldShippingData.name = formData.shipping_method;
                        notificationFire(data.message, generalConstants.NOTIFY_SUCCESS)
                        if (btnName == 'save') {
                            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);

                            router.push({ name: 'edit-shipping-method', params: { id: formData.shipping_method_id } })
                        } else if (btnName == 'save_back') {
                            router.push({ name: 'shipping-method'});
                        }
                    }
                })
                .catch((error) => {
                    (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
                    let message = '';
                    if (typeof (error.response) !== 'undefined') {
                    message = error.response.data.message;
                    } else if (typeof (error.message) !== 'undefined') {
                    message = error.message;
                    } else {
                    message = generalConstants.NOTIFY_WRONG_MESSAGE;
                    }
                    notificationFire(message, generalConstants.NOTIFY_ERROR);
                });
            });
        }

        //Get shipping method data
        const getShippingMethodData = async() => {
            pageLoading.value = true;
            await ApiService.query("shipping-method/" + formData.shipping_method_id)
            .then(({ data }) => {
                if (data.data) {
                    formData.shipping_method_id = data.data.shipping_method_id;
                    formData.trading_partner_id = data.data.trading_partner_id;
                    formData.shipping_method = oldShippingData.name = data.data.shipping_method;
                    formData.shipping_mode = data.data.shipping_mode;
                    formData.third_party_billing = data.data.third_party_billing;
                    formData.function = data.data.function;
                    formData.shipping_details = JSON.parse(data.data.shipping_details);
                    formData.status = (data.data.status == 1) ? true : false;                    
                }
                pageLoading.value = false;
            })
            .catch((error) => {
                pageLoading.value = false;
                let message = '';
                if (typeof (error.response) !== 'undefined') {
                message = error.response.data.message;
                } else if (typeof (error.message) !== 'undefined') {
                message = error.message;
                } else {
                message = generalConstants.NOTIFY_WRONG_MESSAGE;
                }
                notificationFire(message, generalConstants.NOTIFY_ERROR);
            });
        }

        // Get Weight Unit
        const getWeightClass = async() => {
        await ApiService.query("get-weight-class")
        .then(({ data }) => {
            weightClass.value = data;
        })
        .catch((error) => {
            let response = error.response;
            if (response) {
            let status = response.status;
            if (status == 404) {
                router.push({ name: "add-master-package" });
            }
            } 
        });
        }

        // Get Length Unit
        const getLengthClass = async() => {
        await ApiService.query("get-length-class")
        .then(({ data }) => {
            lengthClass.value = data;
        })
        .catch((error) => {
            let response = error.response;
            if (response) {
            let status = response.status;
            if (status == 404) {
                router.push({ name: "add-master-package" });
            }
            } 
        });
        }

        //Generate Current Page Toolbar Dynamic Buttons
        function generateHeaderToolbarButtons(){
            return setCurrentPageActionButtons([{"name" :generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader':false, 'isAllowed': (permission.isEditAllowed)}, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
        }

        onMounted(async () => {
            setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Shipping Method", "path": "shipping-method"}, { name: formatText(route.name), active: true }]);
            let role = loggedInUserRoles();
            let per = loggedInUserPermissions();
            if (per.includes("updateShippingMethod") || role == "Super Admin")
                permission.isEditAllowed = true;
            formData.shipping_method_id = router.currentRoute.value.params.id;       
            if(formData.shipping_method_id > 0 ) {
                await getShippingMethodData();
            }
            await getWeightClass();
            await getLengthClass();
        })

        onUpdated(async () => {
            generateHeaderToolbarButtons();
        })

        return {
            router,
            formRef,
            loading,
            loadingback,
            formData,
            submitForm,
            getShippingMethodData,
            disabled,
            pageLoading,
            generalConstants,
            orderConstants,
            shippingConstants,
            permission,
            oldShippingData,
            weightClass,
            getWeightClass,
            lengthClass,
            getLengthClass,
            packageConstants,
            rules
        }
    },
};
</script>